.dropdown-container {
  &__items {
    all: unset;
    cursor: pointer;
  }
  .caret {
    cursor: pointer;
  }
}

.drop {
  background: #feffff;
  position: absolute;
  flex-direction: column;
  height: fit-content;
  width: 150px;
  padding: 10px;
  gap: 15px;
  display: flex;
  transition: 300ms all;

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    color: #6c6c70;
    font-size: 14px;
    padding: 5px;
    cursor: pointer;
    gap: 4px;

    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: flex;
        width: 18px;
      }
    }
  }
}

.dropdown-container {
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;

  .dropdown__img {
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
}

// #caret-down {
//     transform: rotate(180deg);
// }

// .dropdown-container:hover+.drop {
//     display: flex;
//     transition: .5s all;
// }

.show {
  display: flex;
  transition: 300ms all;
}

.none {
  display: none;
}
