@use "../../Style/variables" as *;

#dashboard-layout {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  #main-main {
    padding: 2rem;
    @media (max-width: $Dashboard-Screen_Mobile-Max) {
      padding: 1rem;
    }
    // @media (max-width: $Dashboard-Screen-Tablet-Max) and (min-width: $Dashboard-Screen-Tablet-Min) {
    //   padding-top: 0rem;
    // }
  }
  #dashboard-back-btn-icon {
    @media (max-width: $Dashboard-Screen_Mobile-Max) {
      display: block;
      p {
        display: none;
      }
    }
  }

  //? Note that is a general btn style in the dashboard
  .new-dashboard-container_Btn-no-bg {
    font-size: 0.9rem;
    background-color: #fff;
    border-radius: 10px;
    padding: 0.8rem 1rem;
    outline: 0;
    border: none;
    color: $primaryColor;
    border: 1px solid $primaryColor;
    &:focus {
      background-color: #dcdcdc;
    }
  }
  .new-dashboard-container_Btn-bg {
    font-size: 0.9rem;
    padding: 0.8rem 1rem;
    outline: 0;
    border: none;
    color: white;
    border-radius: 10px;
    background-color: $primaryColor;
  }
}
