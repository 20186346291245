@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap");
@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  position: relative;
  overflow-x: hidden;
  padding: 0 !important;
}
body {
  background: #f8fffe;

  width: 100%;
}

* {
  font-family: "Work Sans", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
}

#btn {
  cursor: pointer;
}
.new-dashboard-container_Btn-no-bg {
  font-size: 0.9rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.8rem 1rem;
  outline: 0;
  border: none;
  color: $primaryColor;
  border: 1px solid $primaryColor;
}
.new-dashboard-container_Btn-bg {
  font-size: 0.9rem;
  padding: 0.8rem 1rem;
  outline: 0;
  border: none;
  color: white;
  border-radius: 10px;
  background-color: $primaryColor;
}
