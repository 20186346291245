@use "../../../Style/variables" as *;

#plan-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h4.title {
    color: #5e5e5e;
    font-size: 1.2rem;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .radio-wrapper {
      display: flex;
      gap: 1rem;
      padding: 1rem;
      border: 1px solid black;
      border-radius: 10px;
      cursor: pointer;
      align-items: center;
      @media (max-width: $mediumPhones) {
        gap: 0px;
        padding-left: 8px;
      }
      .radio {
        input[type="radio"] {
          transform: scale(2);
        }
      }
      > div {
        display: flex;
        align-items: center;
        gap: 1rem;
        @media (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }
        > div:nth-child(1) {
          // display: flex;
          // flex-direction: column;
          // gap: 4px;
          // p {
          //   font-weight: 500;
          // }
          // > span {
          //   gap: 6px;
          //   display: flex;
          //   padding: 6px 10px;
          //   border-radius: 6px;
          //   align-items: center;
          //   background-color: #08372f1a;
          //   p {
          //     font-size: 12px;
          //   }
          //   > svg {
          //     size: 13px;
          //     background: linear-gradient(90deg, #fcd635 0%, #f7a928 100%);
          //   }
          // }
          h1 {
            span {
              font-size: 16px !important;
              font-weight: 400 !important;
            }
          }
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
          h4 {
            color: #5e5e5e;
            font-size: 0.9rem;
          }
          div {
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
            gap: 1rem;
            width: 100%;
            div {
              display: flex;
              align-items: center;
              gap: 4px;
              svg {
                fill: $primaryColor;
                // width: 1rem;
                // height: 1rem;
              }
              p {
                color: #727272;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
    .standard-radio {
      border-color: $primaryColor;
      background-color: #d8fff1;
      > div {
        > div:nth-child(1) {
          display: flex;
          flex-direction: column;
          gap: 4px;
          p {
            font-weight: 500;
          }
          > span {
            gap: 6px;
            display: flex;
            padding: 6px 10px;
            border-radius: 6px;
            align-items: center;
            background-color: #08372f1a;
            p {
              font-size: 12px;
            }
            > svg {
              size: 13px;
              background: linear-gradient(90deg, #fcd635 0%, #f7a928 100%);
            }
          }
        }
      }
      > span {
        width: fit-content;
        img {
          width: 0.8rem;
        }
      }
    }
    .premium-radio {
      border-color: #cab3e1;
      background-color: #f1ecf6;
      > div {
        > div {
          &:nth-child(1) {
            > span {
              font-size: 0.7rem;
              white-space: nowrap;
            }
          }
          > span {
            width: fit-content;
            img {
              width: 0.8rem;
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      button {
        width: 200px;
        font-size: 0.9rem;
      }
      a {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        color: black;
        text-decoration: none;
        font-size: 0.9rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

#plan-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    h4 {
      color: #5e5e5e;
      font-size: 1.2rem;
      text-transform: capitalize;
    }
  }
  .radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    @media (max-width: $largePhones) {
      grid-template-columns: 1fr;
    }
    .radio-wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      border: 1px solid #c5c5c5;
      padding: 1rem;
      border-radius: 8px;
      cursor: pointer;
      @media (max-width: $mediumPhones) {
        padding: 12px;
      }
    }
  }
  .timeline {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 0.8rem;
    @media (max-width: $smallPhones) {
      font-size: 0.8rem;
    }
    div {
      border-left: 2px solid #d0d5dd;
      display: flex;
      justify-content: space-between;
      padding: 8px 8px;
      span {
        color: #5e5e5e;
      }
      &:nth-child(1) {
        border-color: $primaryColor;
      }
    }
  }
  .total {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    padding: 8px;
    span {
      font-weight: 600;
    }
  }
  .foot-note {
    color: #727272;
    @media (max-width: $smallPhones) {
      font-size: 0.8rem;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    button {
      width: 40%;
      font-size: 1rem;
    }
  }
}
