#current-user-avatar {
  width: fit-content;
  div {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
