@use "../../Style/variables" as *;

#Dashboard-Container {
  display: grid;
  grid-template-columns: 0.42fr 1.55fr;
  width: 100%;
  min-height: 100vh;
  gap: 1rem;
  #Sidebar {
    width: 100%;
    background-color: $Sidebar-BGC;
    padding: 1rem 0rem 1rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 13rem;
    height: 100%;
    .upper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5rem;
      .Logo {
        width: 100%;
      }
      .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        a {
          padding: 0.7rem;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-weight: 500;
          gap: 10px;
          cursor: pointer;
          text-decoration: none;
          color: $primaryBlackColor;
          img {
            color: $primaryBlackColor;
          }
        }
        .active {
          background-color: $primaryColor;
          color: white;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $primaryBlackColor;
      padding-right: 30px;
      align-items: center;
      .profile-pic {
        width: 50px;
        height: 50px;
        margin-bottom: 8px;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h5 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 1rem;
        font-weight: 400;
      }
      .logout {
        display: flex;
        align-items: center;
        gap: 18px;
        margin-top: 2.4rem;
        cursor: pointer;
        img {
          background-color: #fff;
          padding: 6px;
          border-radius: 50%;
        }
        p {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
