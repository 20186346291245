@use "../../Style/variables" as *;

#upgrade-account-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  .wrapper {
    background-color: #fff;
    padding: 2rem;
    padding-bottom: 3rem;
    display: flex;
    outline: 0;
    box-shadow: 0px 4px 30px 0px #00000026;
    border-radius: 1rem;
    flex-direction: column;
    width: 650px;
    gap: 1rem;
    position: relative;
    overflow-y: auto;
    max-height: 94vh;
    @media (max-width: $largePhones) {
      width: 550px;
      padding: 1.4rem;
      padding-top: 1.8rem;
    }
    @media (max-width: $mediumPhones) {
      padding: 1rem;
      padding-top: 1.8rem;
    }
    .close-button {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}
