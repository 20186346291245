@use "../../Style/variables" as *;

#payment-success {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
  padding-bottom: 3rem;
  .illustration-wrapper {
    width: 35%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    > h1 {
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }
    > p {
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }
  }
  .btn-wrapper {
    width: 20%;
    margin-top: 2rem;
  }
}
