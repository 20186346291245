@use "../../Style/variables" as *;

.button {
  width: fit-content;
  text-transform: none !important;
  box-shadow: none;
  border-radius: 10px !important;
  padding: 0.6rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  @media (max-width: $mediumPhones) {
    font-size: 0.85rem;
  }
}
