.nav-logo {
  display: flex;
  padding-left: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;

  h2 {
    color: #19a68e !important;
    font-size: 26px;
    margin: 0;
    font-family: "Sora", sans-serif;

    span {
      color: #ad2fd0 !important;
    }
  }
}
