.search-bar {
  width: 100%;
  div {
    border-radius: 8px;
    gap: 8px;
    width: 100%;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
    input {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}
