$primaryGreenColor: #19a6be;
$primaryBlackColor: #222222;
$successGreenColor: #01d489;
$errorRedColor: #fb4343;
$footerGreenBgColor: #08372f;
$uploadBtn: #19a68e;
$secondaryColor: #d8fff1;

// Media Queries
$smallPhones: 380px;
$mediumPhones: 450px;
$largePhones: 767px;
$laptops: 1023px;
$largeLaptop: 1440px;
$desktop: 2560px;
//

$containerWidth: 1440px;

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more — Extra large screens, TV
$containerWidth: 100%;
$primaryColor: #19a68e;
$greyColor: #6c6c70;
$lightPrimaryColor: #f7fcfb;

$Sidebar-BGC: #d1ede8;
$Folder-BGC: #b2e1d9;

$CreateCertificateSidebarBGC: #348a6b;
$CreateCertificateSidebarInnerItemBGC: #41ac86;
$CreateCertificateSidebarAsidBGCe: #0e523a;

//? Dashboard Media Query
$Dashboard-Screen_Mobile-Max: 576px;
$Dashboard-Screen-Tablet-Max: 968px;
$Dashboard-Screen-Tablet-Min: 768px;
