#input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .label-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    .label {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      font-weight: 500;
      padding-left: 4px;
    }
  }
  .input {
    width: 100%;
    border-radius: 10px;
  }
  .error-message {
    font-size: 0.85rem;
    color: red;
    padding-left: 4px;
  }
}
