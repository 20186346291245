@import "../../Style/variables";

.btn {
  background: $primaryColor;
  color: #fff;
  padding: 1.125rem;
  width: fit-content;
  border: 1px solid $primaryColor;
  outline: none;
  border-radius: 10px;
  padding: 0.75rem;
  text-align: center;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin-top: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &.btnDisable {
    opacity: 1;
    background-color: #b2e1d9;
    cursor: not-allowed;
  }

  &.btnLight {
    color: $primaryColor;
    background-color: #fff;
    border: $primaryColor;
    border: 1px solid $primaryColor;

    &:hover {
      color: #fff;
      background-color: $primaryColor;
      border: none;
      opacity: 0.7;
    }
  }
}
